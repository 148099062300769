import axios from 'axios'
import { setToken, getToken } from '@/units/token'
import { setActivity } from '@/units/activity'
import router from '@/router'
export const baseUrl = "https://guess-admin.maiyuan.online/api/"
// export const baseUrl = "http://192.168.4.40:7888/api/" //本地测试
class HttpRequest {
	constructor(baseUrl) {
		this.baseUrl = baseUrl
	}

	interceptors(instance) {
		// 请求拦截
		instance.interceptors.request.use(config => {
			return config
		}, error => {
			return Promise.reject(error)
		})
		// 响应拦截
		instance.interceptors.response.use(res => {
			const { data, status } = res
			if(data.status == '510000' || data.status == '510001'){
				// alert('弹层 ' + data.msg)
				setActivity('')
				router.push('/home')
				return;
			}
			if(data.status == '400'){
				//假装有个弹层
                console.log("aaaaaaaaaaaaaaaa", res);
				window.Toast(data.msg,10000)
				return data;
			}
			if (data.status == '410000') {
				console.log(111)
				setToken('')
				router.push('/login')
				return;
			}
			return { data, status }

		}, error => {
			return Promise.reject(error)
		})
	}

	request(options) {
		const instance = axios.create()
		let apiAuth = getToken()
		if (apiAuth === false) {
			options = Object.assign({
				baseURL: this.baseUrl,
				headers: {}
			}, options)
		} else {
			options = Object.assign({
				baseURL: this.baseUrl,
				headers: {
					'Authori-zation': 'Bearer ' + apiAuth
				}
			}, options)
		}
		this.interceptors(instance)
		return instance(options)
	}
}
const axios_request = new HttpRequest(baseUrl)
export default axios_request
