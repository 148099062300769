<template>
  <div class="syj_page-width toplogoCustomer">
    <div class="syj_enter-login-content">
      <div class="cursorPointer" @click="homeBlack">
        <img
            v-if="$route.path != '/home'"
            class="homelogo"
            src="https://maiyuan-zhusun.oss-cn-hangzhou.aliyuncs.com/upload/maiyuanlogo.png"
        />
      </div>
      <span @click="PersonalBlack" class="cursorPointer right">Hi！大奖预定人 {{ customer }} ~
        <img v-if="Image" :src="Image"/>
        <img v-else/>
      </span>
    </div>
  </div>
</template>
<script>
import {getMemberInfo} from "../units/api.js";

export default {
  name: "toplogoCustomer",
  data() {
    return {
      customer: " ",
      Image: "",
    };
  },
  methods: {
    PersonalBlack: function () {
      this.$router.push({path: '/Personal-center'})
      // let routeData = this.$router.resolve({ path: "/Personal-center" });
      // window.open(routeData.href, "_blank");
    },
    
    homeBlack() {
      this.$router.push({path: '/home'})
      // let routeData = this.$router.resolve({path: '/home'});
      // window.open(routeData.href, '_blank');
    },
    toplogoCustomer() {
      let that = this;
      getMemberInfo().then((res) => {
        console.log('res客户中心', res)
        if (res == undefined) {
          return
        }
        let response = res.data;
        that.customer = response.data.name;
        that.Image = response.data.avatar_url
      });
    },
  },
  mounted() {
    this.toplogoCustomer();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less">
.toplogoCustomer {
  z-index: 0;
  
  .syj_enter-login-content {
    .cursorPointer.right {
      display: flex;
      justify-content: center;
      align-items: center;
      
      img {
        width: 35px;
        margin-left: 10px;
        border-radius: 50%;
        height: 35px;
      }
    }
    
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: space-between;
    color: #fff;
    align-items: center;
    padding-top: 20px;
    
    div {
      img {
        margin-right: 10px;
      }
      
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
