export const setActivity = (data) => {
	localStorage.setItem('activity', data)
}

export const getActivity = () => {
	const activity = localStorage.getItem('activity')
	if (activity) {
		return activity
	} else {
		return false
	}
}