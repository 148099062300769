import axios_request from '@/units/axios.request.js'
//获取用户信息
export const getMemberInfo = () => {
    return axios_request.request({
        url: 'member_info',
        method: 'get',
    })
}
//发送弹幕
export const sendBulletChat = (msg) => {
    let data = {title: msg}
    return axios_request.request({
        url: "send_bullet_chat",
        method: "post",
        data
    })
}
//投注
export const memberBetting = (data,params) =>{
    return axios_request.request({
        url: "member_betting",
        method: "post",
        data,
        params
    })
}
//生成支付二维码
export const creat_qrcode = (data) =>{
    return axios_request.request({
        url: "creat_qrcode",
        method: "post",
        data,
    })
}
//根据订单id生成支付二维码
export const creat_qrcode_now = (data)=>{
    return axios_request.request({
        url: "creat_qrcode_now",
        method: "post",
        data,
    })
}
//解除订单锁定状态
export const unlock_creat_qrcode = (data) =>{
    return axios_request.request({
        url: "unlock_creat_qrcode",
        method: "post",
        data,
    })
}
//发送弹幕
export const personalguessrecord = (params) => {
    return axios_request.request({
        method: "get",
        url: "guessing_record",
        data: { page: 1, limit: 10 },
        params
    })
}
//当前活动
export const currentActivity = () =>{
    return axios_request.request({
        method: "get",
        url: "current_activity",  
    })
}
//订单明细
export const orderInfo = (order_id) =>{
    let params = {order_id}
    return axios_request.request({
        method: "get",
        url: "order_info_copy",  
        params
    })
}
//编辑订单
export const editOrder = (params, data) =>{
    return axios_request.request({
        method: "put",
        url: "edit_order",  
        data,
        params
    })
}
//个人中心
export const memberInfo = ( ) =>{
    return axios_request.request({ 
    method: "get",
    url: "member_info",      
    })
} 
//会员中心投注明细
export const memberbettingdetails = (params ) =>{  
    return axios_request.request({ 
    method: "get",
    url: "member_betting_details",     
    params 
    })
} 